import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

import Layout from '../components/layout'
import styles from './christmas.module.css'

export default function ChristmasPhotoSession({ location, data }) {
  const [isSuccess, setIsSuccess] = useState(false)

  function handleSubmit(event) {
    setIsSuccess(false)

    event.preventDefault()

    const form = event.target
    const formData = new FormData(form)

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        form.reset()
        setIsSuccess(true)
      })
      .catch(() => alert('Not possible to submit the form. Please, try again.'))
  }

  return (
    <Layout
      location={location}
      packageTypes={data.allContentfulPackageType}
      photoshoots={data.contentfulEntriesGroup}
    >
      <Helmet>
        <title>Juliana de Souza Photography</title>

        <meta
          name="description"
          content="Christmas mini photoshoot. 20-30 minutes of duration. 10 digital pictures delivered in high resolution"
        />

        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-X222HY18ND"
        ></script>

        <script src="/analytics.js"></script>
      </Helmet>

      <main className={styles.main}>
        <div className={styles.header}>
          <h1>Christmas photo session</h1>

          <p>
            The holiday season is a time for love, laughter, and making
            memories. This year, let’s capture that Christmas magic with a
            family photo session that brings the joy of the season into timeless
            keepsakes. Cherish those moments that tell the story of your
            family’s festive joy.
          </p>
        </div>

        <div className={styles.heroGrid}>
          <div className={styles.heroGridMainColumn}>
            <div className={styles.heroGridMainPhoto}>
              <img src="/jpg/christmas/hero-main.jpeg" alt="" />
            </div>
          </div>

          <div className={styles.heroGridSecondaryColumn}>
            <div className={styles.heroGridSecondaryPhoto}>
              <img src="/jpg/christmas/hero-secondary-1.jpeg" alt="" />
            </div>
            <div className={styles.heroGridSecondaryPhoto}>
              <img src="/jpg/christmas/hero-secondary-2.jpeg" alt="" />
            </div>
          </div>
        </div>

        <div className={styles.callToAction}>
          <p>
            I am currently hosting{' '}
            <strong>Christmas mini photo sessions</strong> in my home studio
            located in <strong>Spijkenisse</strong> (only 30 minutes from
            Rotterdam). Each session lasts between{' '}
            <strong>20 to 30 minutes</strong> and includes{' '}
            <strong>20 digital images</strong>. The price is{' '}
            <strong>€95</strong>.
          </p>

          <p>
            Christmas mini photo sessions are seasonal and will end soon. To
            ensure your family has the opportunity to capture these special
            moments during this festive time, we recommend prompt booking.
          </p>

          <a href="#book-session" className={styles.button}>
            Contact me
          </a>
        </div>

        <div className={styles.banner}>
          <div className={styles.bannerPhotoVertical1}>
            <img
              src="/jpg/christmas/banner-vertical-1.jpeg"
              alt="An adorable child stands near a Christmas tree, capturing the magic of the holiday season."
            />
          </div>

          <div className={styles.bannerPhotoHorizontal}>
            <img
              src="/jpg/christmas/banner-main.jpeg"
              alt="Adorable child admiring decorated tree."
            />
          </div>

          <div className={styles.bannerPhotoVertical2}>
            <img
              src="/jpg/christmas/banner-vertical-2.jpeg"
              alt="A joyful little girl runs towards her mother with excitement in front of a beautifully decorated Christmas tree."
            />
          </div>
        </div>

        <div className={styles.moreInfo}>
          <div>
            <h2>How does it work?</h2>

            <p>
              When you show up on your photo day, just wear what makes you feel
              fantastic – we want you to be yourself! After you confirm your
              mini photo session, I'll share a color palette suggestion to make
              your photos look even more amazing. It's like having a style guide
              to help everyone's outfits blend seamlessly for picture-perfect
              moments.
            </p>

            <h2>Reserving Your Spot</h2>

            <p>
              To secure your date, a 50% non-refundable fee is required, along
              with the signing of our photoshoot agreement. The remaining
              balance is due on the day of your photo session. This process
              ensures a smooth reservation and allows us to focus on creating
              beautiful memories for you and your family.{' '}
            </p>
          </div>

          <form
            className={styles.bookSession}
            id="book-session"
            data-netlify="true"
            name="christmas"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            <h2>Are you interested?</h2>

            <p className={styles.bookSessionDescription}>
              If you are interested, please fill-in this form. I will reach out
              to you to coordinate a suitable date as soon as I get your
              message.
            </p>

            {isSuccess && (
              <label>
                <span className={styles.successMessage}>
                  Form successfully sent!
                </span>
              </label>
            )}

            <label>
              <strong>Location:</strong>
              <br />
              Salamanderveen, 309, 3205 TC, Spijkenisse
            </label>

            <label>
              <strong>Your name:</strong>
              <br />
              <input
                name="name"
                type="text"
                required
                className={styles.input}
              />
            </label>

            <label>
              <strong>Your email:</strong>
              <br />
              <input
                name="email"
                type="email"
                required
                className={styles.input}
              />
            </label>

            <label>
              <strong>Your phone number:</strong>
              <br />
              <input
                name="phone"
                type="tel"
                required
                className={styles.input}
              />
            </label>

            <p className={styles.formActionRow}>
              <input type="hidden" name="form-name" value="christmas" />

              <button className={styles.button} type="submit">
                Send
              </button>
            </p>
          </form>
        </div>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ChristmasPage {
    allContentfulPackageType {
      nodes {
        name
        slug
      }
    }

    contentfulEntriesGroup(type: { eq: "Photoshoot" }) {
      items {
        name
        slug
      }
    }
  }
`
